import { createApp } from 'vue'
import App from '@/App.vue'

import router from './router'
import store from './store'
import helpers, { insertToast } from '@/utils/helpers'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import FlagIcon from 'vue-flag-icon'

import bugsnagVue from './plugins/bugsnag'

const app = createApp(App)

const helperPlugins = {
  // eslint-disable-next-line
  install(app, options) {
    app.config.globalProperties.$onUpdateModel = (value) => {
      store.dispatch('forms/updateForm', value)
    }
    app.config.globalProperties.$helpers = helpers
    app.config.globalProperties.$insertToast = insertToast
  },
}

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(helperPlugins)
app.use(FlagIcon)
app.use(bugsnagVue)

app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
